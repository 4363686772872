import { useEffect } from "react";
import Layout from "../components/Layout/Layout";

const ContactPage = () => {
  useEffect(() => {
    const myForm = new VanillaForm($("form.vanilla-form"));
    // console.log(myForm);
  });

  return (
    <Layout showContactSection={false}>
      <div className="wrapper light-wrapper">
        <div className="container inner">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h2 className="section-title text-center">Kontakt mig!</h2>
              <p className="text-center">
                Har du spørgsmål eller søger du tilbud er du mere end velkommen
                til at kontakte mig. Brug kontaktinformationerne eller
                formularen nedenfor.
              </p>
              <div className="space20"></div>
              <div className="row text-center">
                <div className="col-md-6">
                  {" "}
                  <span className="icon icon-color color-default fs-48 mb-10">
                    <i className="si-camping_map"></i>
                  </span>
                  <p>
                    Hillerød | Danmark
                    {/* <br />
                    CVR. 40454519 */}
                  </p>
                </div>
                {/*<div className="col-md-4">*/}
                {/*  {" "}*/}
                {/*  <span className="icon icon-color color-default fs-48 mb-10">*/}
                {/*    <i className="si-phone_phone-ringing"></i>*/}
                {/*  </span>*/}
                {/*  <p>*/}
                {/*    <a className="nocolor" href="tel:+4526206242">*/}
                {/*      +45 26 20 62 42*/}
                {/*    </a>*/}
                {/*    /!* <br />*/}
                {/*    00 (987) 654 32 10 *!/*/}
                {/*  </p>*/}
                {/*</div>*/}
                <div className="col-md-6">
                  {" "}
                  <span className="icon icon-color color-default fs-48 mb-10">
                    <i className="si-mail_mail-2"></i>
                  </span>
                  <p>
                    <a className="nocolor" href="mailto:mail@mh-photo.dk">
                      mail@mh-photo.dk
                    </a>
                    {/* <br />
                    <a className="nocolor" href="mailto:#">
                      asistant@email.com
                    </a> */}
                  </p>
                </div>
              </div>
              <div className="space30"></div>
              {/*<div className="form-container">*/}
              {/*  <form*/}
              {/*    action="contact/vanilla-form.php"*/}
              {/*    method="post"*/}
              {/*    className="vanilla vanilla-form"*/}
              {/*    noValidate={true}*/}
              {/*  >*/}
              {/*    <div className="row text-center">*/}
              {/*      <div className="col-md-6 pr-10">*/}
              {/*        <div className="form-group">*/}
              {/*          <input*/}
              {/*            type="text"*/}
              {/*            className="form-control"*/}
              {/*            name="name"*/}
              {/*            placeholder="* Dit navn"*/}
              {/*            required={true}*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="col-md-6 pl-10">*/}
              {/*        <div className="form-group">*/}
              {/*          <input*/}
              {/*            type="email"*/}
              {/*            className="form-control"*/}
              {/*            name="email"*/}
              {/*            placeholder="* Din e-mail"*/}
              {/*            required={true}*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="col-md-6 pr-10">*/}
              {/*        <div className="form-group">*/}
              {/*          <input*/}
              {/*            type="tel"*/}
              {/*            className="form-control"*/}
              {/*            name="tel"*/}
              {/*            placeholder="Dit tlf. nr"*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="col-md-6 pl-10">*/}
              {/*        <div className="form-group">*/}
              {/*          <input*/}
              {/*            type="text"*/}
              {/*            className="form-control"*/}
              {/*            name="subject"*/}
              {/*            placeholder="Emne"*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="col-12">*/}
              {/*        <textarea*/}
              {/*          name="message"*/}
              {/*          className="form-control"*/}
              {/*          rows={3}*/}
              {/*          placeholder="* Skriv din besked her..."*/}
              {/*          required*/}
              {/*        ></textarea>*/}
              {/*        <div className="space20"></div>*/}
              {/*        <button*/}
              {/*          type="submit"*/}
              {/*          className="btn btn-full-rounded"*/}
              {/*          data-error="Woops, ret venligst fejlene"*/}
              {/*          data-processing="Sender..."*/}
              {/*          data-success="Tak!"*/}
              {/*        >*/}
              {/*          Send*/}
              {/*        </button>*/}
              {/*        <footer className="notification-box"></footer>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </form>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
